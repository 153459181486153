import type { RouteRecordRaw } from 'vue-router'

// const Layout = () => import('@/layout/index.vue')

export default [
  { path: '/login', name: 'login', component: () => import('@/pages/common/login.vue'), meta: { system: 'all' } },
  // { path: '/401', name: 'Error401', component: () => import('@/pages/exception/401.vue'), meta: { title: '授权过期', system: 'all' } },
  // {
  //   path: '/common',
  //   name: 'LayoutBasicRedirect',
  //   component: Layout,
  //   redirect: '/common/redirect',
  //   meta: { system: 'all' },
  //   children: [
  //     {
  //       path: '/common/redirect',
  //       name: 'CommonRedirect',
  //       component: () => import('@/pages/common/route-view.vue'),
  //       redirect: '/redirect',
  //       meta: { system: 'all' },
  //       children: [
  //         {
  //           // 是一种动态路由匹配，表示匹配以/redirect开头的所有。可以匹配 /redirect/example/age
  //           path: '/redirect/:path(.*)',
  //           name: 'RedirectPath',
  //           meta: { system: 'all' },
  //           component: () => import('@/pages/common/redirect.vue'),
  //         },
  //       ],
  //     },
  //   ],
  // },
] as RouteRecordRaw[]
