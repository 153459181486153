import { cloneDeep, get, isObject, isString, trim } from 'lodash-es'
import dayjs from 'dayjs'
import router from '@/router'

export function getQueryParam(param: string | string[], defaultVal = '') {
  const query = router.currentRoute.value?.query ?? {}
  // 根据 object对象的path路径获取值。 如果解析 value 是 undefined 会以 defaultValue 取代
  // @link(https://www.lodashjs.com/docs/lodash.get)
  const val = get(query, param) ?? defaultVal
  // 解码地址
  // @link(https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/decodeuricomponent)
  return decodeURIComponent(val)
}

/**
 * @description 判断数据是否为脏数据
 * @param obj 任何数据
 * @returns Boolean
 * @example
 * isDirty(null) // true
 * isDirty(undefined) // true
 * isDirty('') // true
 * isDirty('  ') // true
 * isDirty(true) // false
 * isDirty(false) // false
 * isDirty(0) // false
 */
export function isDirty<T = any>(obj: T) {
  if (isString(obj)) {
    return trim(obj) === ''
  }

  return obj == null
}

/**
 * @description 将有脏数据字段的对象过滤后，得到新的对象
 *
 * 不影响原来的对象
 * @param obj 任意对象
 * @returns Object
 */
export function getFreshObject<T extends object>(obj: T) {
  if (!isObject(obj))
    return undefined
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isDirty(v)))
}

/**
 * 把日期转换为毫秒
 * @param date
 * @returns 毫秒
 */
export function transformDateToMilliseconds(date: string | number | Date | dayjs.Dayjs | null | undefined) {
  return dayjs(date).valueOf()
}

const FILE_DICTIONARY: any = {
  // 文本
  text: ['txt', 'rtf'],
  // 音频文件
  audio: ['mp3', 'wav', 'wma', 'm4a', 'flac', 'aac', 'ogg', 'm4r'],
  // 视频
  video: ['mp4', 'avi', 'webm', 'mkv', 'wmv', 'mpg', 'mov', 'flv'],
  // ppt
  ppt: ['ppt', 'pptx'],
  // excel
  excel: ['xlsx', 'xls', 'csv'],
  // word
  word: ['doc', 'docx'],
  // pdf
  pdf: ['pdf'],
  // 压缩文件
  rar: ['7z', 'rar', 'zip', 'apz', 'ar', 'bz', 'car', 'dar', 'cpgz', 'f', 'ha', 'hbc', 'hbc2', 'hbe', 'hpk', 'hyp'],
  // exe类型
  exe: ['exe'],
  // 图片类型
  image: ['jpg', 'gif', 'bmp', 'png', 'jpeg', 'tiff', 'tif', 'psd', 'svg'],
  other: ['other'],
  folder: ['folder'],
}

export function getFileSuffix(fileName: string) {
  const matched = fileName.match(/\.(\w+)$/)
  return matched ? matched[1] : undefined
}

/**
 * 根据文件名返回文件对应的类型
 * @param fileName 文件名
 * @returns 文件类型
 */
export function getFileType(fileName: string): FileType {
  const suffix = getFileSuffix(fileName) ?? 'folder'

  for (const key in FILE_DICTIONARY) {
    if (Object.prototype.hasOwnProperty.call(FILE_DICTIONARY, key)) {
      const element = FILE_DICTIONARY[key]
      if (element.includes(suffix)) {
        return key as FileType
      }
    }
  }

  return 'other'
}

export const cache = (() => {
  let caches: any[] = []
  return function (arg: any = [], clear: boolean = false) {
    if (caches.length === 0 || clear) {
      caches = arg
    }
    return caches
  }
})()

type Descripted<T> = {
  [K in keyof T]: {
    readonly value: K
    readonly label: T[K]
  }
}[keyof T]

export function enumToOptions<T extends object>(enumeration: T): Descripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter(key => Number.isNaN(Number(key)))
    .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map(key => ({ label: enumeration[key], value: key }))
}

/**
 * 深度克隆传入对象，然后从克隆对象中删除值为 dirty 的字段，不影响传入对象。
 * @see isDirty
 * @param obj 传入对象，不会被修改
 * @returns 一个新对象
 */
export function omitDirtyField<T extends Record<string, any>>(obj: T) {
  // TODO: 需要测试
  const nextObj = cloneDeep(obj)
  return Object.fromEntries(Object.entries(nextObj).filter(([_, v]) => !isDirty(v))) as {
    [K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
  }
}
