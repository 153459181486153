import type { RouteRecordRaw } from 'vue-router'
import { basicRouteMap } from '../router-modules'

export default [
  {
    path: '/iframe',
    name: 'IFrame',
    component: basicRouteMap.Iframe,
    meta: { },
  },
] as RouteRecordRaw[]
