import type { Handler } from 'mitt'
import mitt from 'mitt'
import type { RouteLocationNormalized } from 'vue-router'

const emitter = mitt()

const key = Symbol('ROUTE_CHANGE')

let latestRoute: RouteLocationNormalized

/**
 * 开启路由监听
 * @param to
 */
export function setRouteEmitter(to: RouteLocationNormalized) {
  emitter.emit(key, to)
  latestRoute = to
}

/**
 * 监听路由变化
 * @param handler 监听后到以后的处理器
 * @param immediate 是否立刻监听，默认为true
 */
export function listenerRouteChange(handler: (route: RouteLocationNormalized) => void, immediate = true) {
  emitter.on(key, handler as Handler)
  if (immediate && latestRoute)
    handler(latestRoute)
}

/**
 * 移除路由监听
 */
export function removeRouteListener() {
  emitter.off(key)
}
