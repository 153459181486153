<script setup lang="ts">
import { useLayoutMenuProvide } from './components/page-container/context'

const appStore = useAppStore()
// const appListStore = useAppListStore()
const { theme } = storeToRefs(appStore)
// const cacheConfigStore = useWorkBenchCacheConfigStore()
// const userStore = useUserStore()
// const { userInfo } = storeToRefs(userStore)
const { antd } = useI18nLocale()
const layoutMenu = useLayoutMenu()
useLayoutMenuProvide(layoutMenu, appStore)
// onMounted(async () => {
//   const login = await isLogin()
//   if (login) {
//     console.log('app init ===>')

//     await cacheConfigStore.getCacheConfig()
//   }
// })

// watch(userInfo, async (newUser) => {
//   if (newUser) {
//     await cacheConfigStore.refreshWorkBenchConfig()
//     appListStore.initApp()
//   }
// })
</script>

<template>
  <a-config-provider :theme="theme" :locale="antd">
    <!--
        提供可消费 provide/inject 的 message.xxx、Modal.xxx、notification.xxx 的静态方法，可以简化 useMessage 等方法需要手动植入 contextHolder 的问题。
        提供基于 .ant-app 的默认重置样式，解决原生元素没有 antd 规范样式的问题。
    -->
    <a-app class="h-full font-chinese antialiased">
      <TokenProvider>
        <RouterView />
      </TokenProvider>
    </a-app>
  </a-config-provider>
</template>
