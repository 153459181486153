import { changeSystem } from '@/router/routes'

export interface App {
  name: string
  code: string
  active: boolean
  icon: string
  id: number
  url?: string
  authUrl?: string
}

const whiteList = ['login', 'workbench', 'Error401']
export const useAppListStore = defineStore('app-list', () => {
  const cacheConfigStore = useWorkBenchCacheConfigStore()
  const { apps } = storeToRefs(cacheConfigStore)
  const allAppList = ref<App[]>([])
  const currentApp = ref<App | null>(null)
  const storageCache = useStorage<Map<number, App>>('APP_LIST', new Map())
  const map = reactive<Map<number, App>>(storageCache.value)
  const route = useRoute()
  const router = useRouter()

  function initApp() {
    const systemConfig = {
      name: '系统配置',
      code: 'config',
      id: -1,
      active: false,
      // icon: '/backend-resource/workbench/5.svg',
    }

    allAppList.value = apps.value ? [...apps.value, systemConfig] : [systemConfig]
  }

  function _setDefaultApp() {
    const systemId: any = localStorage.getItem('system')
    // const defaultApp = allAppList.value.find(item => item.id === Number(systemId))
    // if (defaultApp) {
    //   currentApp.value = defaultApp
    //   addApp(defaultApp)
    // }

    const defaultApp = storageCache.value.get(Number(systemId))
    if (defaultApp) {
      currentApp.value = defaultApp
      addApp(defaultApp)
    }
  }
  if (!whiteList.includes(route.name as string)) {
    _setDefaultApp()
  }
  watch(
    () => route.name,
    (val, oldVal) => {
      if (val === 'workbench') {
        currentApp.value = null
        useStorage('system', String).value = ''
      }
      else if (val === 'login') {
        currentApp.value = null
        map.clear()
      }
      if (oldVal === 'login' && val !== 'workbench') {
        _setDefaultApp()
      }
    },
    { immediate: true },
  )
  watch(
    () => map,
    (val) => {
      storageCache.value = val
    },
    { deep: true },
  )
  function addApp(app: App) {
    map.set(app.id, app)
  }
  function delApp(appId: number) {
    if (currentApp.value?.id === appId) {
      const openAppId = getPreviousElement(appId)
      if (openAppId) {
        openApp(openAppId)
      }
    }
    map.delete(appId)
    if (map.size === 0) {
      router.push({ path: '/workbench', replace: true })
    }
  }
  // 获取某个键的前一个元素
  function getPreviousElement(appId: number) {
    const entries = Array.from(map)
    const index = entries.findIndex(entry => entry[1].id === appId)
    if (index > 0) {
      return entries[index - 1][0]
    }
    else if (index === 0 && entries[index + 1]) {
      return entries[index + 1][0]
    }
    return null
  }
  function clearApp() {
    map.clear()
    useStorage('system', String).value = ''
    // router.push({ path: '/workbench', replace: true })
  }
  function openApp(appId: number) {
    // if (currentApp.value?.id === appId) {
    //   return
    // }
    if (map.size) {
      map.forEach((item) => {
        item.active = false
      })
    }
    let app = map.get(appId)
    if (!app) {
      app = allAppList.value.find(item => item.id === appId)
      if (!app) {
        throw new Error('app not found')
      }
      addApp(app as App)
    }
    app.active = true
    currentApp.value = app
    changeSystem(currentApp.value, appId === Number(localStorage.getItem('system')))
  }
  const menuList = computed(() => {
    return Array.from(map.values())
  })

  return { allAppList, currentApp, menuList, initApp, delApp, clearApp, openApp }
})
