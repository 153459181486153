import type { RouteRecordRaw } from 'vue-router'

export const useRouterStore = defineStore('router', () => {
  const routeList = shallowRef<RouteRecordRaw[]>([])

  function setRouteList(routes: RouteRecordRaw[]) {
    routeList.value = routes
  }

  function clearRouteList() {
    routeList.value.splice(0)
  }

  return { routeList, setRouteList, clearRouteList }
})
