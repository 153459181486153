export default [
  {
    path: '/dashboard',
    name: 'EamDashBoard',
    component: () => import('@/pages/eam/dashboard/index.vue'),
    meta: { title: '仪表盘', locale: 'menu.eam.dashboard' },
  },
  {
    path: '/provider',
    name: 'EamProvider',
    component: () => import('@/pages/eam/provider/index.vue'),
    meta: { title: '供应商管理', locale: 'menu.eam.provider' },
  },
  {
    path: '/department',
    name: 'EamDepartment',
    component: () => import('@/pages/eam/department/index.vue'),
    meta: { title: '部门管理', locale: 'menu.eam.department' },
  },
  {
    path: '/extra',
    name: 'EamExtra',
    component: () => import('@/pages/eam/extra/index.vue'),
    meta: { title: '资产台账', locale: 'menu.eam.extra' },
  },
  {
    path: '/mainplan',
    name: 'EamMainplan',
    component: () => import('@/pages/eam/mainplan/index.vue'),
    meta: { title: '维保计划', locale: 'menu.eam.mainplan' },
  },
  {
    path: '/inspection',
    name: 'EamInspection',
    component: () => import('@/pages/eam/inspection/index.vue'),
    meta: { title: '设备巡检', locale: 'menu.eam.inspection' },
  },
  {
    path: '/work-order',
    name: 'EamWorkOrder',
    component: () => import('@/pages/eam/work-order/index.vue'),
    meta: { title: '工单管理', locale: 'menu.eam.work-order' },
  },
  {
    path: '/inventory',
    name: 'EamInventory',
    component: () => import('@/pages/eam/inventory/index.vue'),
    meta: { title: '库存管理', locale: 'menu.eam.inventory' },
  },
  {
    path: '/report',
    name: 'EamReport',
    component: () => import('@/pages/eam/report/index.vue'),
    meta: { title: '工作日报', locale: 'menu.eam.report' },
  },
  {
    path: '/notify',
    name: 'EamNotify',
    component: () => import('@/pages/eam/notify/index.vue'),
    meta: { title: '站内信', locale: 'menu.eam.notify' },
  },
]
