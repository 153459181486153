/**
 * 从缓存查询系统公开配置
 * 高频率调用用缓存，数据具有滞后性
 */
export const getCachesSystemPublicConfigApi = () => useGet<PublicConfig>('/deploy/system/status/public-config-cache')

/**
 * 从数据库查询工作台配置
 */
export const getWorkBenchConfigApi = () => useGet('/deploy/system-setting/workbench-config-db')

/**
 * 从缓存查询工作台配置
 */
export const getWorkBenchCacheConfigApi = () => useGet('/deploy/system/status/workbench-config-cache')

/**
 * 修改工作台配置
 */
export const updateWorkBenchConfigApi = (configJson: any) => usePut('/deploy/system-setting/workbench-config', configJson)
