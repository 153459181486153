import { concat, filter, find, includes, isUndefined, map, reduce, sortBy } from 'lodash-es'
import { getWorkBenchCacheConfigApi } from '@/api/system'
import { getUserByIdApi } from '@/api/user'

export const useWorkBenchCacheConfigStore = defineStore('cacheConfig', () => {
  const { userId } = storeToRefs(useUserStore())
  const cacheConfig = ref({
    project: {
      name: '',
      icon: '',
      workbenchBackgroundImage: '',
      loginBackgroundImage: '',
    },
    categories: [] as Array<any>,
    apps: [] as Array<any>,
  })

  const apps = computed(() => cacheConfig.value.apps)
  const categories = computed(() => cacheConfig.value.categories)
  const project = computed(() => cacheConfig.value.project)

  async function refreshWorkBenchConfig(isLogin?: boolean) {
    cacheConfig.value = await getWorkBenchCacheConfigApi()
    if (isLogin) {
      return resetConfig()
    }
    return []
  }

  async function resetConfig() {
    if (isUndefined(userId.value))
      return
    const brenchUser = await getUserByIdApi(userId.value)

    if (includes(['DEPLOYER', 'MANAGER'], brenchUser.userType)) {
      return sortApps()
    }

    updateCacheConfigWithApiData(brenchUser.workbenchConfig)

    return sortApps()
  }

  function updateCacheConfigWithApiData(workbenchConfig: string | null) {
    if (workbenchConfig) {
      const { categories } = JSON.parse(workbenchConfig)
      cacheConfig.value.categories = getUserWorkBrenchConfigCategories(categories) ?? []
    }
    else {
      cacheConfig.value.apps = []
      cacheConfig.value.categories = []
    }
  }

  function getUserWorkBrenchConfigCategories(categories: any[]) {
    const nextCategories = map(categories, (category) => {
      const aimCategory = find(cacheConfig.value.categories, ['id', category.id])

      const nextApps = reduce(category.apps, (result: any[], appId: number) => {
        const fullApp = find(cacheConfig.value.apps, ['id', appId])
        return fullApp ? concat(result, fullApp) : result
      }, [])

      return {
        ...aimCategory,
        apps: nextApps,
      }
    })

    return nextCategories
  }

  function sortApps() {
    const sortedCategory = sortBy(categories.value, ['index'])

    sortedCategory.forEach((category) => {
      category.apps = sortBy(category.apps, ['index'])
    })

    return filter(sortedCategory, o => (o.apps && o.apps.length > 0))
  }

  const getSortedCategories = () => sortApps()

  return { apps, categories, project, refreshWorkBenchConfig, getSortedCategories }
})
