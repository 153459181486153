import type { RouteRecordRaw } from 'vue-router'
import type { MenuData, MenuDataItem } from '@/layout/basic-layout/typing'
import { i18n } from '@/locales'

export const useMenuStore = defineStore('menu', () => {
  const menuData = shallowRef<MenuData>([])

  function getMenuData(routes: RouteRecordRaw[]) {
    const menuData = [] as MenuData
    const { hasAccess } = useAccess()

    routes.forEach((route) => {
      // 如果无角色权限，则不添加到列表中
      if (route.meta?.access) {
        const isAccess = hasAccess(route.meta.access)
        if (!isAccess)
          return
      }

      // 如果角色权限为空，或者有角色权限，则加入到列表中
      const menuItem: MenuDataItem = formatMenu(route)

      if (route.children && route.children.length > 0) {
        menuItem.children = []
        menuItem.children = getMenuData(route.children)
      }

      menuData.push(menuItem)
    })

    return menuData
  }

  function clearMenu() {
    menuData.value = []
  }

  // 组装menu的一项中的数据
  function formatMenu(route: RouteRecordRaw, path?: string) {
    return {
      title: () => renderTitle(route),
      icon: route.meta?.icon || '',
      isAntIcon: route.meta?.isAntIcon ?? true,
      path: path ?? route.path,
      hideInMenu: route.meta?.hideInMenu || false,
      parentKeys: route.meta?.parentKeys || [],
      hideInBreadcrumb: route.meta?.hideInBreadcrumb || false,
      hideChildrenInMenu: route.meta?.hideChildrenInMenu || false,
      locale: route.meta?.locale,
      keepAlive: route.meta?.keepAlive || false,
      name: route.name as string,
      url: route.meta?.url || '',
      target: route.meta?.target || '_blank',
    } as MenuDataItem
  }

  // 渲染标题，有国际化用国际化，没有的话，用默认的title
  function renderTitle(route: RouteRecordRaw) {
    const { title, locale } = route.meta || {}
    if (!title)
      return ''
    return locale ? (i18n.global as any).t(locale) : title
  }

  function setMenuData(routes: RouteRecordRaw[]) {
    menuData.value = getMenuData(routes)
  }

  return { menuData, setMenuData, clearMenu }
})
