import baseLoading from '@/components/base-loading/index.vue'

/**
 * 使用loading
 * @param config LoadingType
 * @returns 返回open和close方法
 */
export function useLoading(config: LoadingType = {}) {
  const loadingConstructor = createApp(baseLoading, { ...config })
  let instance: any = null
  let startTime = 0
  let endTime = 0
  const minTime = config.minTime || 0

  // 打开loading
  const open = (target: HTMLElement = document.body) => {
    if (!instance)
      instance = loadingConstructor.mount(document.createElement('div'))
    if (!instance || !instance.$el)
      return
    target.appendChild(instance.$el)
    startTime = performance.now()
  }

  // 关闭loading
  const close = () => {
    if (!instance || !instance.$el)
      return
    endTime = performance.now()
    // 打开时间与关闭时间的差值小于最小持续时间，
    // 则补足时间差后关闭，保证loading的最小持续时间
    if (endTime - startTime < minTime) {
      setTimeout(() => {
        instance.$el.parentNode?.removeChild(instance.$el)
      }, Math.floor(minTime - (endTime - startTime)))
    }
    else {
      instance.$el.parentNode?.removeChild(instance.$el)
    }
  }

  return {
    open,
    close,
  }
}
