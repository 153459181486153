/**
 * 用户登陆
 */
export function loginApi(data: LoginParam) {
  return new Promise((resolve, reject) => {
    usePost('/deploy/authentication/login', data, { token: false, loading: true, customDev: false }).then((user) => {
      user.userType = [user.userType]
      resolve(user as StoreUser)
    }).catch(e => reject(e))
  })
}

/**
 * 退出登陆
 */
export const logoutApi = () => useGet<boolean>('/deploy/authentication/logout')

/**
 * 判断是否登录
 */
export const isLoginApi = () => useGet<boolean>('deploy/authentication/islogined')
