import type { RouteRecordRaw } from 'vue-router'
import { omit } from 'lodash-es'
import routesMap from './system-routes'
import { rootRoute } from './constant'
import router from '.'

const cacheRoutesMap: Record<number, RouteRecordRaw[]> = {}

let init = true
const appId = useStorage('system', String)

// 根据系统类型获取路由列表
export function getDynamicRoutes() {
  const appListStore = useAppListStore()
  const { currentApp } = storeToRefs(appListStore)
  // const app = allAppList.value.find(app => app.id === appId.value)
  if (!appId.value || !currentApp.value)
    return rootRoute.children
  const result = routesMap[currentApp.value.code as SystemType]
  return result
}

// 初始化路由和菜单列表
export async function initRoutes() {
  const isLogin = await useUserStore().isLogin()
  // 如果没有登录权限，则不添加路由
  if (!isLogin)
    return

  // 添加动态路由
  setAddRoute()
  // 添加menu的menu数据到store中
  useMenuStore().setMenuData(getDynamicRoutes())
}

// 切换系统
export function changeSystem(app: any, reOpen = false, replace = true) {
  if (reOpen) {
    router.push({ path: routesMap[app.code][0].path, replace, query: { appId: app.id } })
    return
  }
  if (appId.value == app.id)
    return
  appId.value = app.id
  // 重置路由
  resetRoute()
  // 添加路由
  setAddRoute()
  // 添加menu的menu数据到store中
  useMenuStore().setMenuData(getDynamicRoutes())
  router.push({ path: '/', replace, query: { appId: app.id } })
}

// 添加动态路由
export function setAddRoute() {
  const filterRoutes = setFilterRouteEnd()
  filterRoutes.forEach((route) => {
    router.addRoute(route)
  })
  router.addRoute({
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    redirect: '/workbench',
  })

  useRouterStore().setRouteList(filterRoutes)
}

// 重置路由
export function resetRoute() {
  setFilterRouteEnd().forEach((route) => {
    const routeName = route.name
    if (routeName)
      router.hasRoute(routeName) && router.removeRoute(routeName)
  })
  router.hasRoute('notFound') && router.removeRoute('notFound')
  useRouterStore().clearRouteList()
  useMenuStore().clearMenu()
  useMultiTab().clear()
}

/**
 * 根据权限，系统，获取平铺路由
 */
export function setFilterRouteEnd() {
  if (cacheRoutesMap[appId.value as any]?.length) {
    return cacheRoutesMap[appId.value as any]
  }
  const filterRouteEnd = wrapRoutesInRoute(flatRoutes(getDynamicRoutes()))

  filterRouteEnd[0].children = setFilterRoute(filterRouteEnd[0].children ?? [])
  const res = [{ ...rootRoute, children: [...filterRouteEnd, ...rootRoute.children!] }]
  // cacheRoutesMap[appId.value as any] = res
  return res
}

function setFilterRoute(routes: RouteRecordRaw[]) {
  const filterRoute = [] as RouteRecordRaw[]
  routes.forEach((route) => {
    if (route.meta?.access) {
      useAccess().hasAccess(route.meta.access) && filterRoute.push(route)
    }
    else {
      filterRoute.push(route)
    }
  })

  return filterRoute
}

// 给拉平的路由添加一个父级路由
function wrapRoutesInRoute(routes: RouteRecordRaw[]) {
  init = true
  let flatRoutesList = [] as RouteRecordRaw[]
  if (routes.length > 0) {
    init = false
    flatRoutesList = flatRoutes(routes)
  }

  // 拿到拉平后的路由，然后统一添加一个父级的路由,通过这层路由实现保活的功能
  const parentRoute: RouteRecordRaw = {
    path: '/',
    name: 'ROOT_EMPTY_PATH',
    // redirect: flatRoutesList[0].path,
    redirect: init ? '/workbench' : flatRoutesList[0].path,
    children: flatRoutesList,
  }
  init = false
  return [parentRoute]
}

function flatRoutes(routes: RouteRecordRaw[]) {
  if (routes.length <= 0)
    return []

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].children) {
      routes = routes.slice().concat(routes[i].children ?? [])
    }
  }

  return routes.map(route => omit(route, ['children'])) as RouteRecordRaw[]
}
