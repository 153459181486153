import pulseSpin from '@/components/base-loading/spin/pulse-spin.vue'
import rectSpin from '@/components/base-loading/spin/rect-spin.vue'
import planeSpin from '@/components/base-loading/spin/plane-spin.vue'
import cubeSpin from '@/components/base-loading/spin/cube-spin.vue'
import preloaderSpin from '@/components/base-loading/spin/preloader-spin.vue'
import chaseSpin from '@/components/base-loading/spin/chase-spin.vue'
import dotSpin from '@/components/base-loading/spin/dot-spin.vue'

const loadingMap = new Map<LoadingSpin, Component>()

loadingMap.set('PULSE', pulseSpin)
loadingMap.set('RECT', rectSpin)
loadingMap.set('PLANE', planeSpin)
loadingMap.set('CUBE', cubeSpin)
loadingMap.set('PRELOADER', preloaderSpin)
loadingMap.set('CHASE', chaseSpin)
loadingMap.set('DOT', dotSpin)

export { loadingMap }
