import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/project',
    name: 'Project',
    component: () => import('@/pages/config/project/index.vue'),
    meta: { title: '项目配置', icon: 'HddOutlined' },
  },
  {
    path: '/app',
    name: 'App',
    component: () => import('@/pages/config/app/index.vue'),
    meta: { title: '应用配置', icon: 'HddOutlined' },
  },
] as RouteRecordRaw[]
