import { ContentTypeEnum } from '@/enums/http-enum'

/**
 * 获取用户列表
 */
export async function queryUsersApi(params: UserParam) {
  const pageUser = await useGet<Paging<User>>('/deploy/user/page', params)
  pageUser.records.forEach((user: any) => {
    if (user.workbenchConfig != null) {
      user.workbenchConfig = JSON.parse(user.workbenchConfig)
    }
  })
  return pageUser
}

/**
 * 修改密码
 */
export const updatePasswordApi = (params: { password?: string, confirmPassword?: string, userId?: number }) => usePut('/deploy/user/updatePassword', params, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 根据id删除用户
 */
export const deleteUserApi = (id: string) => useDelete(`/deploy/user/${id}`)

/**
 * 修改类型和权限
 */
export const editAuthAndTypeApi = (params: any) => usePut('/deploy/user', params, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 添加用户
 */
export const addUserApi = (param: User) => usePost('/deploy/user', param)

/**
 * 查询登录登出日志
 */
export const queryLoginRecords = (params: any) => useGet('/runtime/user/page-log-user-auth', params)

/**
 * 修改用户
 */
export const updateUserApi = (params: any) => usePut('/deploy/user', params)

/**
 * 根据id查询用户信息
 */
export const getUserByIdApi = (id: number) => useGet(`/deploy/user/${id}`)

/**
 * 按照部门查询
 */
export const getUserByDept = () => useGet('/runtime/user/by-dept')

/**
 * 查询当前登录的用户
 */
export const getLoginedUser = () => useGet('/deploy/authentication/logined-user')
