import type { RouteRecordRaw } from 'vue-router'

export const ROOT_ROUTE_REDIRECT_PATH = '/'
const Layout = () => import('@/layout/index.vue')

export const rootRoute: RouteRecordRaw = {
  path: '/',
  name: 'rootPath',
  redirect: ROOT_ROUTE_REDIRECT_PATH,
  component: Layout,
  children: [
    {
      path: '/workbench',
      name: 'workbench',
      component: () => import('@/pages/workbench/index.vue'),
    },
  ],
}
