import { type App, createApp } from 'vue'
import { createPinia } from 'pinia'

import { theme } from 'ant-design-vue'
import {
  setupAccessDirective,
  setupImgLazyDirective,
  setupLoadingDirective,
} from './directive'
import Root from './App.vue'
import router from './router'
import '@/router/router-guard'
import 'ant-design-vue/dist/reset.css'
import '@/assets/styles/reset.css'
import 'uno.css'
import { setupI18n } from './locales'

const { defaultAlgorithm, defaultSeed } = theme

defaultAlgorithm(defaultSeed)

const pinia = createPinia()

async function start() {
  const app: App = createApp(Root)
  // 注册pinia状态管理
  app.use(pinia)
  await setupI18n(app)
  setupDirective(app)
  // 注册路由
  app.use(router)
  app.mount('#app')
  /**
   * 设置此项为 true 可以在浏览器开发工具的“性能/时间线”页中启用对组件初始化、编译、渲染和修补的性能表现追踪。
   * 仅在开发模式和支持 performance.mark API 的浏览器中工作
   */
  app.config.performance = true
}

function setupDirective(app: App) {
  // 注册loading自定义指令
  setupLoadingDirective(app)
  setupAccessDirective(app)
  setupImgLazyDirective(app)
}

start()
