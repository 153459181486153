import type { RouteRecordRaw } from 'vue-router'
// 安消一体化系统路由
export default [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/safety-consumer/home/index.vue'),
    meta: { title: '首页', icon: 'HddOutlined' },
  },
  {
    path: '/safe',
    name: 'Safe',
    component: () => import('@/pages/safety-consumer/safe/index.vue'),
    meta: { title: '安消一体化系统', icon: 'HddOutlined' },
  },
  {
    path: '/device',
    name: 'Device',
    meta: { title: '物联设备', icon: 'PartitionOutlined' },
    redirect: '/device/list',
    children: [
      {
        path: '/device/list',
        name: 'DeviceList',
        component: () => import('@/pages/safety-consumer/device/list.vue'),
        meta: { title: '资产列表' },
      },
    ],
  },
  {
    path: '/alarm',
    name: 'Alarm',
    meta: { title: '报警中心', icon: 'AlertOutlined' },
    redirect: '/alarm/runtime',
    children: [
      {
        path: '/alarm/runtime',
        name: 'AlarmRuntime',
        component: () => import('@/pages/safety-consumer/alarm/runtime.vue'),
        meta: { title: '实时事件' },
      },
      {
        path: '/alarm/history',
        name: 'AlarmHistory',
        component: () => import('@/pages/safety-consumer/alarm/history.vue'),
        meta: { title: '历史事件' },
      },
    ],
  },
  {
    path: '/tv_wall',
    name: 'TVWall',
    meta: { title: '安防中心', icon: 'VideoCameraAddOutlined' },
    redirect: '/tv_wall/manage',
    children: [
      {
        path: '/tv_wall/manage',
        name: 'TVWallManage',
        component: () => import('@/pages/safety-consumer/tv-wall/manage/index.vue'),
        meta: { title: '视频管理' },
      },
      {
        path: '/tv_wall/display',
        name: 'TVWallDisplay',
        component: () => import('@/pages/safety-consumer/tv-wall/display/index.vue'),
        meta: { title: '实时视频' },
      },
    ],
  },
] as RouteRecordRaw[]
