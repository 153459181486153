import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/user',
    name: 'User',
    meta: { title: '用户中心', icon: 'PartitionOutlined' },
    redirect: '/user/manage',
    children: [
      {
        path: '/user/manage',
        name: 'UserManage',
        component: () => import('@/pages/system-manage/user/manage/index.vue'),
        meta: { title: '用户管理' },
      },
      {
        path: '/user/department',
        name: 'UserDepartment',
        component: () => import('@/pages/system-manage/user/department/index.vue'),
        meta: { title: '部门管理' },
      },
    ],
  },
  {
    path: '/log',
    name: 'Log',
    meta: { title: '日志中心', icon: 'PartitionOutlined' },
    redirect: '/log/login',
    children: [
      {
        path: '/log/login',
        name: 'LogLogin',
        component: () => import('@/pages/system-manage/log/login/index.vue'),
        meta: { title: '登录日志' },
      },
      {
        path: '/log/device-record',
        name: 'LogDeviceRecord',
        component: () => import('@/pages/system-manage/log/device-record/index.vue'),
        meta: { title: '设备控制记录' },
      },
    ],
  },
] as RouteRecordRaw[]
