import type { RouteRecordRaw } from 'vue-router'
import intergrationRoutes from './intergration'
import iotRoutes from './iot'
import config from './config'
import systemManage from './system-manage'
import iframe from './iframe'
import eam from './eam'

const routesMap: Record<SystemType, RouteRecordRaw[]> = {
  'intergration': intergrationRoutes,
  'iot': iotRoutes,
  'config': config,
  'systemManage': systemManage,
  'iframe': iframe,
  'eam': eam,
}

export default routesMap
