import { theme as antdTheme } from 'ant-design-vue'
import type { ThemeConfig } from 'ant-design-vue/es/config-provider/context'
import type { AnimationNameValueType } from '@/config/default-setting'
import type { ContentWidth, LayoutType, ThemeType } from '@/layout/basic-layout/typing'
import defaultSetting from '@/config/default-setting'

export interface LayoutSetting {
  title?: string
  subjectName?: string
  platformName?: string
  logo?: string
  theme: ThemeType
  collapsed: boolean
  drawerVisible: boolean
  colorPrimary?: string
  layout?: LayoutType
  contentWidth?: ContentWidth
  fixedHeader?: boolean
  fixedSider?: boolean
  splitMenus?: boolean
  watermark?: boolean
  header?: boolean
  footer?: boolean
  menu?: boolean
  menuHeader?: boolean
  colorWeak?: boolean
  colorGray?: boolean
  multiTab?: boolean
  multiTabFixed?: boolean
  headerHeight?: number
  copyright?: string
  keepAlive?: boolean
  accordionMode?: boolean
  leftCollapsed?: boolean
  compactAlgorithm?: boolean
  animationName?: AnimationNameValueType
}

export const useAppStore = defineStore('app', () => {
  // defaultAlgorithm 默认算法
  // darkAlgorithm 暗色算法
  // compactAlgorithm 紧凑算法
  const { darkAlgorithm, compactAlgorithm, defaultAlgorithm } = antdTheme
  const layoutSetting = reactive<LayoutSetting>(defaultSetting)
  const themeConfig = reactive<ThemeConfig>({
    // 主题算法
    algorithm: antdTheme.defaultAlgorithm,
    // 更改主题的token
    token: {
      colorBgContainer: '#fff',
      colorPrimary: layoutSetting.colorPrimary,
    },
    // 用于修改各个组件的 Component Token 以及覆盖该组件消费的 Alias Token
    components: {
      // 例如修改某个组件
      //   Radio: {
      //     colorPrimary: '#00b96b',
      //   },
    },
  })

  const locale = ref<string>(lsLocaleState.value)
  const toggleLocale = (locale: string) => {
    lsLocaleState.value = locale
  }

  // 切换紧凑型和非紧凑型
  const toggleCompact = (isCompact = true) => {
    layoutSetting.compactAlgorithm = isCompact
    const algorithm = layoutSetting.theme === 'dark' ? [darkAlgorithm] : [defaultAlgorithm]
    isCompact && algorithm.push(compactAlgorithm)
    themeConfig.algorithm = algorithm
  }

  // 切换主题
  const toggleTheme = (theme: ThemeType) => {
    if (layoutSetting.theme === theme) {
      toggleDark(false)
      return
    }
    else {
      toggleDark(false)
      return
    }

    layoutSetting.theme = theme
    if (theme === 'light' || theme === 'inverted') {
      if (themeConfig.token)
        themeConfig.token.colorBgContainer = '#fff'
      if (themeConfig.components?.Menu)
        delete themeConfig.components.Menu
      toggleDark(false)
    }
    else if (theme === 'dark') {
      toggleDark(true)
      if (themeConfig.token)
        themeConfig.token.colorBgContainer = 'rgb(36, 37, 37)'
      if (themeConfig.components) {
        themeConfig.components = {
          ...themeConfig.components,
          Menu: {
            colorItemBg: 'rgb(36, 37, 37)',
            colorSubItemBg: 'rgb(36, 37, 37)',
            menuSubMenuBg: 'rgb(36, 37, 37)',
          } as any,
        }
      }
    }
    toggleCompact(layoutSetting.compactAlgorithm)
  }

  // 切换drawer显示/隐藏
  const toggleDrawerVisible = (visible: boolean) => {
    layoutSetting.drawerVisible = visible
  }

  // 切换主题颜色
  const toggleColorPrimary = (color: string) => {
    layoutSetting.colorPrimary = color
    if (themeConfig.token)
      themeConfig.token.colorPrimary = color
  }

  // 如果加载进来是暗色模式，就切换到暗色模式
  if (isDark.value) {
    // toggleTheme('dark')
    toggleTheme('light')
  }

  // 监听isDark的变化
  watch(isDark, () => {
    toggleTheme('light')
    // if (isDark.value)
    //   toggleTheme('dark')
    // else toggleTheme('light')
  })

  watch(preferredLanguages, () => {
    toggleLocale(preferredLanguages.value[0])
  })

  const toggleCollapsed = (collapsed: boolean) => {
    layoutSetting.collapsed = collapsed
  }

  function toggleGray(isGray = true) {
    layoutSetting.colorGray = isGray
    const dom = document.querySelector('body')
    if (dom) {
      if (isGray) {
        toggleWeak(false)
        dom.style.filter = 'grayscale(100%)'
      }
      else { dom.style.filter = '' }
    }
  }
  if (layoutSetting.colorGray)
    toggleGray(true)

  function toggleWeak(isWeak = true) {
    layoutSetting.colorWeak = isWeak
    const dom = document.querySelector('body')
    if (dom) {
      if (isWeak) {
        toggleGray(false)
        dom.style.filter = 'invert(80%)'
      }
      else { dom.style.filter = '' }
    }
  }
  if (layoutSetting.colorWeak)
    toggleWeak(true)

  const toggleLayout = (layout: LayoutType) => {
    if (layoutSetting.theme === 'inverted' && layout === 'mix')
      layoutSetting.theme = 'light'

    if (layout !== 'mix')
      layoutSetting.splitMenus = false
    else layoutSetting.leftCollapsed = true

    if (layout === 'top')
      layoutSetting.contentWidth = 'Fixed'
    else layoutSetting.contentWidth = 'Fluid'

    layoutSetting.layout = layout
  }

  const changeSettingLayout = (key: keyof LayoutSetting, value: any) => {
    if (key === 'theme')
      toggleTheme(value as ThemeType)
    else if (key === 'colorPrimary')
      toggleColorPrimary(value)
    else if (key === 'layout')
      toggleLayout(value as LayoutType)
    else if (key === 'compactAlgorithm')
      toggleCompact(value)
    else if (key === 'colorWeak')
      toggleWeak(value)
    else if (key === 'colorGray')
      toggleGray(value)
    else if (key in layoutSetting)
      (layoutSetting as any)[key] = value
  }

  return { layoutSetting, theme: themeConfig, locale, toggleLocale, toggleTheme, toggleCollapsed, toggleDrawerVisible, changeSettingLayout, toggleColorPrimary, toggleGray, toggleWeak }
})
