// 参考https://cn.vitejs.dev/guide/features#glob-import
const routerModules = import.meta.glob([
  '@/pages/**/*.vue',
])

export const basicRouteMap = {
  // iframe模式下使用
  Iframe: () => import('@/pages/common/iframe.vue'),
  // 一般用于存在子集的页面
  RouteView: () => import('@/layout/components/route-view.vue'),
  // 空页面
  ComponentError: () => import('@/pages/exception/component-error.vue'),
}

function checkEager(module: any) {
  /**
   * 如果import.meta.glob设置了 import:'default'
   * 则需要使用module.default的方式获取
   */
  if (typeof module === 'object' && 'default' in module)
    return module.default

  return module
}

export function getRouterModule(path?: string) {
  if (!path)
    return basicRouteMap.ComponentError

  // 判断是否在basicRouteMap中存在
  if (path in basicRouteMap)
    return (basicRouteMap as any)[path]

  // 判断开头是不是/
  if (path.startsWith('/'))
    path = path.slice(1)

  // 组装数据格式
  const fullPath = `/src/pages/${path}.vue`
  const fullPathIndex = `/src/pages/${path}/index.vue`

  if (fullPathIndex in routerModules)
    return checkEager(routerModules[fullPathIndex])

  // 返回插件信息
  return checkEager(routerModules[fullPath])
}
