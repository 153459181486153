import { includes } from 'lodash-es'
import { isLoginApi, logoutApi } from '@/api/login'
import { getLoginedUser } from '@/api/user'

// export const userState = useStorage('user', {}, undefined, {
//   serializer: {
//     read: (v: any) => v ? JSON.parse(v) : null,
//     write: (v: any) => JSON.stringify(v),
//   },
// })

export const useUserStore = defineStore('user', () => {
  const user = shallowRef<LoginedUser>()

  const avatar = computed(() => 'https://q3.itc.cn/q_70/images03/20240519/1b4e7565b46241b68288ae482412a866.jpeg')
  const nickname = computed(() => user.value?.loginName ?? '')
  const role = computed(() => user.value?.userType)
  const userId = computed(() => user.value?.userId)

  const logout = async () => {
    // 退出登录
    // 1. 清空用户信息
    try {
      await logoutApi()
    }
    finally {
      user.value = undefined
      useRouterStore().clearRouteList()
      useMenuStore().clearMenu()
      useAppListStore().clearApp()
    }
  }

  const refreshUserInfo = async () => {
    user.value = await getLoginedUser()
  }

  const isLogin = async () => (await isLoginApi())

  const isRole = (type: UserType) => includes(role.value, type)

  return {
    user,
    role,
    avatar,
    nickname,
    userId,
    logout,
    isLogin,
    refreshUserInfo,
    isRole,
  }
})
