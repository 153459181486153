import { useIntersectionObserver } from '@vueuse/core'
import type { App, Directive, ObjectPlugin } from 'vue'

// export const directivePlugin: ObjectPlugin = {
//   install(app) {
//     app.directive('img-lazy', {
//       mounted(el, binding) {
//         // el:指令绑定的那个元素 Img
//         // binding:binding.value 指令等于号后面绑定的表达式，图片url
//         // isIntersecting是否进入到视口区域
//         const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
//           if (isIntersecting) {
//             // 进入到视口区域
//             el.src = binding.value
//             stop()
//           }
//         })
//       },
//     })
//   },
// }

const imgLazyDirective: Directive = (el, binding) => {
  // el:指令绑定的那个元素 Img
  // binding:binding.value 指令等于号后面绑定的表达式，图片url
  // isIntersecting是否进入到视口区域
  const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
    if (isIntersecting) {
      // 进入到视口区域
      el.src = binding.value
      stop()
    }
  })
}

export function setupImgLazyDirective(app: App) {
  app.directive('img-lazy', imgLazyDirective)
}
