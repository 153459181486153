import { AxiosError } from 'axios'
import { initRoutes } from './routes'
import router from '@/router'
import { useMetaTitle } from '@/composables/meta-title'
import { setRouteEmitter } from '@/utils/route-listener'
import { getVideoPlayerStatus } from '@/api/video'
import type { App } from '@/stores/app-list'
// import { isDirty } from '@/utils/tools'

// const allowList = ['/login', '/error', '/401', '/404', '/403']
const loginPath = '/login'

const appListLocalStore = useStorage<Map<number, App>>('APP_LIST', new Map())

router.beforeEach(async (to, _, next) => {
  setRouteEmitter(to)

  // 给跳转的页面添加meta信息
  const app = appListLocalStore.value.get(Number(to.query.appId))
  if (app) {
    const { name, icon, url, authUrl } = app
    to.meta = { ...to.meta, title: name, icon, url, authUrl }
  }

  const userStore = useUserStore()
  const appListStore = useAppListStore()
  const cacheConfigStore = useWorkBenchCacheConfigStore()
  const isLogin = await userStore.isLogin()

  // 如果未登录，并且要跳转的是登录页，跳转到登录页
  if (to.path === loginPath && !isLogin) {
    next()
  }
  else {
    if (!isLogin) {
      // 跳转到其他页面，但是没登录，则跳转到登录页
      // await userStore.logout()
      // query: { redirect: encodeURIComponent(to.fullPath) }
      next({ path: loginPath })
    }
    else if (isLogin && to.path === loginPath) {
      // 登录了，但是要跳转到登录页，则跳转到首页
      next('/')
    }
    else {
      const videoStatus = await getVideoPlayerStatus()
      useStorage('video_status', '').value = videoStatus as string

      try {
        // 存储用户信息到userStore中
        await userStore.refreshUserInfo()
        await cacheConfigStore.refreshWorkBenchConfig(true)
        appListStore.initApp()
        if (useRouterStore().routeList.length === 0) {
          await initRoutes()
          next({ ...to, replace: true })
        }
        else {
          next()
        }
      }
      catch (e) {
        if (e instanceof AxiosError && e?.response?.status === 401) {
          next({ path: '/401' })
        }
      }
    }
  }
})

router.afterEach((to) => {
  useMetaTitle(to)
  useLoadingCheck()
  useScrollToTop()
})
