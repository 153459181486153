import { ContentTypeEnum } from '@/enums/http-enum'

/**
 * 根据设备编号获取视频
 */
export const getVideoByDeviceCodeApi = (deviceCode: string) => useGet<Video>('/deploy/video-device', { deviceCode })

/**
 * 给设备绑定视频
 */
export function bindVideoToDeviceApi(video: Video) {
  return usePost<Video>('/deploy/video-device', video)
}

/**
 * 将视频从设备上解绑
 */
export const unBindVideoFromDeviceApi = (deviceCode: string) => useDelete<boolean>(`/deploy/video-device?deviceCode=${deviceCode}`)

/**
 * 获取设备直播流
 */
export const getVideoDeviceLiveApi = (channelName: string, deviceCode: string) => useGet<VideoDeviceLive>('/link/video-device/live', { channelName, deviceCode })

/**
 * 云台图像控制
 */
export const controlVideoDeviceViewApi = (deviceCode: string, videoView: VideoView) => usePost<any>('/link/video-device/video-view', { deviceCode, videoView }, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 视频设备云台方向控制
 */
export const controlVideoDirectionApi = (deviceCode: string, direction: VideoDirection) => usePost<any>('/link/video-device/video-direction', { deviceCode, direction }, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 查看录像回放
 */
export const getVideoDevicePlayBackApi = (deviceCode: string, date: string, fromTime: string, toTime: string) => useGet<VideoDeviceLive>('/link/video-device/playback', { deviceCode, date, fromTime, toTime })

/**
 * 获取视频播放器类型
 */
export const getVideoPlayerStatus = () => useGet<VideoPlayerType>('/deploy/system/status/video-player')

/**
 * 按设备组树形查询视频设备
 */
export const getVideoDeviceByGroup = () => useGet('/deploy/video-device/by-device-group-tree')

/**
 * 查询全部视频设备（编号和名称）
 */
export const getVideoDeviceListApi = () => useGet<VideoDeviceCodeAndName[]>('/deploy/video-device/list')
