import type { RouteRecordRaw } from 'vue-router'

export default [
  { path: '/device/general', name: 'General', component: () => import('@/pages/engine/device/general.vue'), meta: { title: '设备概况', isAntIcon: false, icon: 'icon-shebeiguanli' } },
  {
    path: '/device-link',
    name: 'DeviceLink',
    redirect: '/device-link/device-model',
    meta: { title: '物联管理', isAntIcon: false, icon: 'icon-yunweiguanli' },
    children: [
      { path: '/device-link/device-model', name: 'DeviceLinkDeviceModel', component: () => import('@/pages/engine/device-model/index.vue'), meta: { title: '产品管理' } },
      { path: '/device-link/device', name: 'DeviceLinkDevice', component: () => import('@/pages/engine/device/index.vue'), meta: { title: '设备管理' } },
    ],
  },
  {
    path: '/driver',
    name: 'Driver',
    redirect: '/driver/driver',
    meta: { title: '驱动管理', isAntIcon: false, icon: 'icon-fuwuqiguanli' },
    children: [
      { path: '/driver/driver', name: 'DriverDriver', meta: { title: '驱动仓库' }, component: () => import('@/pages/engine/driver/index.vue') },
      { path: '/driver/driver-instance', name: 'DriverDriverInstance', meta: { title: '驱动实例' }, component: () => import('@/pages/engine/driver-instance/index.vue') },
    ],
  },
  {
    path: '/rule',
    name: 'Rule',
    redirect: '/rule/link-manage',
    meta: { title: '规则引擎', isAntIcon: false, icon: 'icon-shujufenxi' },
    children: [
      { path: '/rule/link-manage', name: 'RuleLinkManage', meta: { title: '联动管理' }, component: () => import('@/pages/linkage/manage/index.vue') },
      { path: '/rule/link-record', name: 'RuleLinkRecord', meta: { title: '联动记录' }, component: () => import('@/pages/linkage/record/index.vue') },
    ],
  },
  {
    path: '/event',
    name: 'Event',
    meta: { title: '平台事件', isAntIcon: false, icon: 'icon-gonggaoguanli' },
    redirect: '/event/runtime',
    children: [
      {
        path: '/event/runtime',
        name: 'EventRuntime',
        component: () => import('@/pages/safety-consumer/alarm/runtime.vue'),
        meta: { title: '实时事件' },
      },
      {
        path: '/event/history',
        name: 'EventHistory',
        component: () => import('@/pages/safety-consumer/alarm/history.vue'),
        meta: { title: '历史事件' },
      },
    ],
  },
] as RouteRecordRaw[]
